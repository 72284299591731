import React from 'react';


const InnerBanner = (props) => {
    const {title,description} = props
  return (
    <div className="banner-bg -mt-2">
        <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-16 h-56 flex items-center">
          <div>
            <h1 className="text-white text-7xl font-medium">{title}</h1>
            <p className="text-gray-300 text-tiny w-8/12">{description}</p>
          </div>
        </div>
    </div>
  );
};

export default InnerBanner;