import React from 'react';


const FeatureContent = (props) => {
    const {title,image,icon,description,direction} = props
  return (
    <div className={`py-5 relative overflow-hidden ${ direction === 'left' ? 'featureContent-gray-bg' : 'bg-white'}`}>
        <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-16">
            <div className="grid xs:grid-cols-1 gap-4 sm:grid-cols-2">
                {direction === "right" ? (<div className="w-10/12 py-8 xs:py-0 flex items-center feat-content-large">
                    <img className="w-full relative z-10"  src={`../../images/${image}.png`} alt="" />
                </div>) : null }
                <div className="w-full lg:py-20 sm:py-5">
                    <div className=" p-5 inline-block background-gradient rounded-xl shadow-lg mb-5">
                        <img src={`../../images/${icon}.png`} className="w-6 text-white" />
                    </div>
                    <h4 className="text-2xl font-medium text-gray-900 mb-5">{title}</h4>
                    <p className="text-xl text-gray-500 font-normal">{description}</p>
                </div>
                {direction === "right" ? (<div className="w-10/12 py-8 xs:py-0 flex items-center feat-content-small">
                    <img className="w-full relative z-10"  src={`../../images/${image}.png`} alt="" />
                </div>) : null }
                {direction === "left" ? (<div className="w-full py-8 text-left xs:flex xs:items-center">
                    <img className="w-10/12 relative z-10 float-left"  src={`../../images/${image}.png`} alt="" />
                </div>) : null }
            </div>
        </div>    
    </div>  
  );
};

export default FeatureContent;