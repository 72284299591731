import React from "react";
import { Switch, Route, HashRouter as Router } from 'react-router-dom';
import Home from "./pages/Marketing/Home";
import Product from "./pages/Marketing/product";
import Price from "./pages/Marketing/Pricing";
import AboutUs from "./pages/Marketing/AboutUs";
import TermsCondition from "./pages/Marketing/TermsCondition";
import Privacy from "./pages/Marketing/Privacy";

export default function App() {
  return (
  <Router>
			<Switch>
				<Route path="/" exact component={Home} />
        <Route path="/product" component={Product} />
        <Route path="/price" component={Price} />
        <Route path="/aboutUs" component={AboutUs} />
        <Route path="/termsCondition" component={TermsCondition} />
        <Route path="/privacy" component={Privacy} />
      </Switch>
		</Router>
  );
}
