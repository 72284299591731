import { CheckIcon as CheckIconSolid, PlusCircleIcon } from '@heroicons/react/solid'
import { useState } from 'react'
import { Fragment } from 'react'
import { CheckIcon, XIcon, InformationCircleIcon} from '@heroicons/react/solid'
import ContactModal from './modal/ContactModal';

const pricing = {
  tiers: [
    {
      title: 'AURA',
      unitCostMonthly: '7500',
      monthlyPrice: '650',
      yearlyPrice: '5000',
      description: 'Unit Cost',
      features: [
          'Temperature', 
          'Humidity'
        ],
    },
    {
        title: 'AURA PRO',
        unitCostMonthly: '9500',
        monthlyPrice: '700',
        yearlyPrice: '5500',
        description: 'Unit Cost',
        features: [
          'LPG/LNG (Propane/Butane/Methane)',
            'Temperature', 
            'Humidity'
          ],
      },
      {
        title: 'AURA PRO +',
        unitCostMonthly: '12500',
      monthlyPrice: '700',
      yearlyPrice: '5500',
      description: 'Unit Cost',
      features: [
        'Smoke',
        'Carbon Monoxide',
          'Temperature', 
          'Humidity'
        ],
      },
      {
        title: 'MACRO',
        unitCostMonthly: '24000',
        monthlyPrice: '1500',
        yearlyPrice: '16000',
        description: 'Unit Cost',
        features: [
          'Carbon Monoxide',
          'Carbon Dioxide',
          'PM 2.5',
            'Temperature', 
            'Humidity'
          ],
      },
  ],
}

const tiers = [
  {
    name: 'individual',
    href: '#',
    priceMonthly: 29,
    description: 'Quis eleifend a tincidunt pellentesque. A tempor in sed.',
  },
  {
    name: 'company',
    href: '#',
    priceMonthly: 59,
    description: 'Orci volutpat ut sed sed neque, dui eget. Quis tristique non.',
  },
]

const sections = [
  {
    name: 'Features',
    features: [
      { name: 'Mobile Application', tiers: { individual: true, company: true } },
      { name: 'App Notifications', tiers: { individual: true, company: true } },
      { name: 'Custom Thresholds', tiers: { individual: true, company: true } },
      { name: 'Custom Sync Intervals', tiers: { individual: true, company: true} },
      { name: 'Alert Frequency', tiers: { individual: true, company: true } },
      { name: 'Email Notifications', tiers: { individual: false, company: true } },
      { name: 'Multiple Email Addition for alerts', tiers: { individual: false, company: true } },
      { name: 'Data Logging', tiers: { individual: '1 Day' ,company: '30/60/90 Days'} },
      { name: 'Notification Report', tiers: { individual: false, company: true } },
      { name: 'Daily Average Report', tiers: { individual: false, company: true } },
      { name: 'Detailed Report', tiers: { individual: false, company: true } },
      { name: 'Dedicated Account Manager', tiers: { individual: false, company: true} },
      { name: 'Email Support', tiers: { individual: true, company: true } },
      { name: 'Phone Support', tiers: { individual: false, company: true } },
      { name: 'Connect over WiFi', tiers: { individual: true, company: true  } },
      { name: 'Connect over GSM', tiers: { individual: 'Launching soon', company: 'Launching soon'  } },
      { name: 'Cloud Subscription Cost', tiers: {  individual: 'NIL', company: 'Free for first 3 months'  } },
    ],
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Price = () => {
  const [monthlyPrice, setMonthlyPrice] = useState(true);
  const [contactModal, setContactModal] = useState(false);

  return (
    <>
    {contactModal ? (<ContactModal open={contactModal} onClose={()=> setContactModal(false)} />) : null}
    <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-16 py-16 banner-top-margin  bg-white -mt-2">
        <div className="flex flex-col items-center justify-center">
            <h2 className="text-5xl font-extrabold text-gray-900 sm:text-5xl sm:leading-none sm:tracking-tight lg:text-6xl">
            Cloud Pricing - Upto 50 Units
            </h2>
            <p className="mt-6 max-w-2xl text-1xl text-gray-500">
            Unit price includes 3 months of cloud cost
            </p>
            <div className="relative mt-6 bg-gray-100 rounded-full p-0.5 flex self-center sm:mt-8">
              <button
                type="button"
                onClick={() => setMonthlyPrice(true)}
                className={`${monthlyPrice ? 'background-gradient text-white' : 'text-gray-700' } relative  border-gray-200 rounded-full shadow-sm p-2 w-1/2 text-sm font-medium  whitespace-nowrap focus:outline-none focus:ring-2  focus:z-10 sm:w-auto sm:px-8`}
              >
                Monthly billing
              </button>
              <button
                type="button"
                onClick={() => setMonthlyPrice(false)}
                className={`${monthlyPrice ? 'text-gray-700' : 'background-gradient text-white' } ml-0.5 relative border border-transparent rounded-full p-2 w-1/2 text-sm font-medium whitespace-nowrap focus:outline-none focus:ring-2 focus:z-10 sm:w-auto sm:px-8`}
              >
                Yearly billing
              </button>
            </div>
        </div>
      {/* Tiers */}

        <div className="mt-12  grid xs:grid-cols-1 sm:grid-cols-2 sm:gap-4 lg:grid lg:grid-cols-4 lg:space-y-0 lg:gap-x-4">
          {pricing.tiers.map((tier) => (
                <div key={tier.name} className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200">
                  <div className="p-6">
                    <h2 className="text-xl leading-6 font-medium theme-text-color">{tier.title}</h2>
                    <p className="mt-4 text-sm text-gray-500">{tier.description}</p>
                    <p className="mt-8">
                      <span className="text-4xl font-extrabold text-gray-900">{tier.unitCostMonthly}</span>{' '}
                    </p>
                    <PlusCircleIcon className="flex-shrink-0 h-5 w-5 theme-text-color" aria-hidden="true" />
                    {monthlyPrice ? ( <p className="mt-1">
                      <span className="text-4xl font-extrabold text-gray-900">{tier.monthlyPrice}</span>{' '}
                      <span className="text-base font-medium text-gray-500">/month</span>
                    </p>) : ( <p className="mt-1">
                      <span className="text-4xl font-extrabold text-gray-900">{tier.yearlyPrice}</span>{' '}
                      <span className="text-base font-medium text-gray-500">/Year</span>
                    </p>)}
                  </div>
                  <div className="pt-6 pb-8 px-6">
                    <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">SENSORS</h3>
                    <ul role="list" className="mt-6 space-y-4">
                      {tier.features.map((feature) => (
                        <li key={feature} className="flex space-x-3">
                          <CheckIconSolid className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                          <span className="text-sm text-gray-500">{feature}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
        </div>
      
      
      <div className="flex items-center justify-center my-14">
        <div className="max-w-7xl p-3 rounded-md bg-blue-50">
          <div className="flex px-8">
            <div className="flex-shrink-0">
              <InformationCircleIcon className="h-5 w-5 text-blue-600" aria-hidden="true" />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-tiny text-gray-900">Custom pricing for units > 50. Taxes Applicable @18%</p>
            </div>
          </div>
      </div>
      <button
          type="button"
          onClick={() => setContactModal(true)}
          className="background-gradient text-white ml-3 relative border border-transparent rounded-md py-3 w-1/2 text-sm font-medium whitespace-nowrap focus:outline-none focus:ring-2 focus:z-10 sm:w-auto sm:px-8"
        >
          Contact Us
        </button>
    </div>

      <div className="max-w-7xl mx-auto bg-white -mt-2">
        {/* xs to lg */}
        {/* <div className="space-y-24 lg:hidden">
          {tiers.map((tier) => (
            <section key={tier.name}>
              <div className="px-4 mb-8">
                <h2 className="text-lg leading-6 font-medium text-gray-900">{tier.name}</h2>
                <p className="mt-4">
                  <span className="text-4xl font-extrabold text-gray-900">{tier.priceMonthly}</span>
                  <span className="text-base font-medium text-gray-500">/mo</span>
                </p>
                <p className="mt-4 text-sm text-gray-500">{tier.description}</p>
                <a
                  href={tier.href}
                  className="mt-6 block w-full bg-gradient-to-r from-orange-500 to-pink-500 border border-transparent rounded-md shadow py-2 text-sm font-semibold text-white text-center hover:to-pink-600"
                >
                  Buy {tier.name}
                </a>
              </div>

              {sections.map((section) => (
                <table key={section.name} className="w-full">
                  <caption className="bg-gray-50 border-t border-gray-200 py-3 px-4 text-sm font-medium text-gray-900 text-left">
                    {section.name}
                  </caption>
                  <thead>
                    <tr>
                      <th className="sr-only" scope="col">
                        Feature
                      </th>
                      <th className="sr-only" scope="col">
                        Included
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {section.features.map((feature) => (
                      <tr key={feature.name} className="border-t border-gray-200">
                        <th className="py-5 px-4 text-sm font-normal text-gray-500 text-left" scope="row">
                          {feature.name}
                        </th>
                        <td className="py-5 pr-4">
                          {typeof feature.tiers[tier.name] === 'string' ? (
                            <span className="block text-sm text-gray-700 text-right">{feature.tiers[tier.name]}</span>
                          ) : (
                            <>
                              {feature.tiers[tier.name] === true ? (
                                <CheckIcon className="ml-auto h-5 w-5 text-green-500" aria-hidden="true" />
                              ) : (
                                <MinusIcon className="ml-auto h-5 w-5 text-gray-400" aria-hidden="true" />
                              )}

                              <span className="sr-only">{feature.tiers[tier.name] === true ? 'Yes' : 'No'}</span>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ))}

              <div className="border-t border-gray-200 px-4 pt-5">
                <a
                  href="#"
                  className="block w-full bg-gradient-to-r from-orange-500 to-pink-500 border border-transparent rounded-md shadow py-2 text-sm font-semibold text-white text-center hover:to-pink-600"
                >
                  Buy {tier.name}
                </a>
              </div>
            </section>
          ))}
        </div> */}

        {/* lg+ */}
        <div>
          <table className="w-full h-px table-fixed border">
            <tbody className=" border-gray-200 divide-y divide-gray-200">
             
              {sections.map((section) => (
                <Fragment key={section.name}>
                  <tr>
                    <th
                      className="py-3 pl-6 bg-gray-50 text-xl font-medium text-gray-900 text-left"
                      scope="colgroup"
                    >
                      {section.name}
                    </th>
                    <th
                      className="py-3 pl-6 bg-gray-50 text-xl font-medium text-gray-900 text-left"
                      scope="colgroup"
                    >
                      Individual
                    </th>
                    <th
                      className="py-3 pl-6 bg-gray-50 text-xl font-medium text-gray-900 text-left"
                      scope="colgroup"
                    >
                      Company
                    </th>
                  </tr>
                  {section.features.map((feature) => (
                    <tr key={feature.name}>
                      <th className="py-5 pl-6 pr-6 text-tiny font-normal text-gray-500 text-left" scope="row">
                        {feature.name}
                      </th>
                      {tiers.map((tier) => (
                        <td key={tier.name} className="py-5 px-6">
                          {typeof feature.tiers[tier.name] === 'string' ? (
                            <span className="block text-tiny text-gray-700">{feature.tiers[tier.name]}</span>
                          ) : (
                            <>
                              {feature.tiers[tier.name] === true ? (
                                <CheckIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
                              ) : (
                                <XIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                              )}

                              <span className="sr-only">
                                {feature.tiers[tier.name] === true ? 'Included' : 'Not included'} in {tier.name}
                              </span>
                            </>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </>
    
  )
}

export default Price
