import React from 'react';
import { NavLink } from 'react-router-dom';
import { NewspaperIcon, PhoneIcon, SupportIcon } from '@heroicons/react/outline'

const supportLinks = [
    {
      name: 'Sales',
      href: '#',
      description:
        'Varius facilisi mauris sed sit. Non sed et duis dui leo, vulputate id malesuada non. Cras aliquet purus dui laoreet diam sed lacus, fames.',
      icon: PhoneIcon,
    },
    {
      name: 'Technical Support',
      href: '#',
      description:
        'Varius facilisi mauris sed sit. Non sed et duis dui leo, vulputate id malesuada non. Cras aliquet purus dui laoreet diam sed lacus, fames.',
      icon: SupportIcon,
    },
    {
      name: 'Media Inquiries',
      href: '#',
      description:
        'Varius facilisi mauris sed sit. Non sed et duis dui leo, vulputate id malesuada non. Cras aliquet purus dui laoreet diam sed lacus, fames.',
      icon: NewspaperIcon,
    },
    {
        name: 'Media Inquiries',
        href: '#',
        description:
          'Varius facilisi mauris sed sit. Non sed et duis dui leo, vulputate id malesuada non. Cras aliquet purus dui laoreet diam sed lacus, fames.',
        icon: NewspaperIcon,
      },
  ]

const RealTimeAccess = () => {
  return (
    <div className="bg-white py-14">
      <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-16 items-center">
      {/* Header */}
      <div className="relative pb-16">
        <div className="relative w-full text-center max-w-7xl mx-auto lg:px-8">
            <h4 className="text-gradient text-lg font-semibold">24/7 REAL TIME DATA ACCESS</h4>
          <h1 className="text-5xl font-extrabold text-black md:text-5xl lg:text-5xl">Monitor from anywhere across the globe</h1>
        </div>
      </div>

      {/* Overlapping cards */}
      <div className='flex justify-center m-auto'>
        <img className="w-4/5 relative z-10"  src="../../images/notifi-mockup.jpg" alt="" />
      </div>
      <button className="bg-white mt-3 rounded-full w-full flex items-center justify-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
      <NavLink
        to="/product"
        exact
        className="inline-flex items-center px-4 py-1.5 border border-transparent text-lg rounded-md shadow-sm text-white background-gradient"
      >
            View Product Features
      </NavLink>
        </button>
    </div>
    </div>
    
  );
};

export default RealTimeAccess;