import React from 'react';
import Navigation from '../../components/Navigation';
import Footer from '../../components/Footer';
import InnerBanner from '../../components/InnerBanner';
import FeatureContent from '../../components/FeatureContent';

const featureContent = [
    {
        title: "Real Time Monitoring",
        icon: "monitor",
        image: "Real_Time_Monitoring",
        description: "Devices that are capable of generating data 24/7. You get to decide the frequency of the data sync interval and it can be as low as you want it to be. Mobile App with a dashboard keeps you always updated with what’s happening at your premises, enabling you to take corrective measures when required. ",
        direction: "right",
    },
    {
        title: "Remote Monitoring",
        icon: "remote",
        image: "Remote_Monitoring",
        description: "With our IoT enabled devices and cloud application, you get to monitor your premises from anywhere across the globe as compared to traditional monitors that force you to be on your organization network to retrieve reports. Truly Remote Monitoring in all senses. ",
        direction: "left",
    },
    {
        title: "Instant Notifications",
        icon: "notification",
        image: "Instant_Notifications",
        description: "Don’t wait for alerts to be notified to you. Instant notifications push alerts to your phone as soon as they happen enabling you to put in corrective measures to save your precious goods and business. You can setup the thresholds in a way where you get alerts beforehand, enabling you to address the corrective measure with buffer time.",
        direction: "right",
    },
    {
        title: "Easy Installation",
        icon: "installation",
        image: "Easy_Installation",
        description: "All we require is a power source and a Wi-Fi connection, no additional cabling is required. Our devices are super easy to install and we do over the air updates to the firmware, so you do not need to worry about upgrades. We provide a detailed, daily average and a notifications report. If you need data in a different format, we can set that up as per your template and requirement.",
        direction: "left",
    },

    {
        title: "Reports",
        icon: "notification",
        image: "Instant_Notifications",
        description: "We provide multiple reports that help you do analysis of the environmental data that helps you improve the standards of your premises and also reduce your expenses.",
        direction: "right",
    },
]

const Product = () => {
  return (
    <div>
        <Navigation />
        <InnerBanner  title="Product Features"/>
        {featureContent.map((item) => (
        <FeatureContent 
            title={item.title} 
            description={item.description}
            icon={item.icon}
            image={item.image}
            direction={item.direction}
        />)
        )}
        <Footer />
    </div>
  );
};

export default Product;