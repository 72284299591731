import React from 'react';

const faqs = [
    {
      question: 'What are the different parameters I can monitor using the devices?',
      answer1:
        'Currently, we help you monitor temperature, humidity, carbon monoxide, carbon dioxide, PM2.5, Butane, Propane and Methane.',
      answer2:
      'In addition, if your industry requires a specific gas to be monitored, we customize our devices to suit your requirements.',
    },
    {
      question: 'Can I customize the alerts for each sensor?',
      answer1:
        'Yes, you can customize the thresholds for each sensor based on your industry and requirement. In addition, you can also send email alerts to multiple Email addresses at a sensor level giving you more flexibility to monitor your premises.',
    },
    {
      question: 'What is the frequency of alerts and data sync?',
      answer1:
        'Users get to decide the frequency of the alerts and data sync from the devices to the cloud. This is user managed and can be changed on the fly as per your requirement. ',
      answer2:
        'The best part is, this does not impact the alerts, if your data sync interval is longer, you still get an alert if a breach is identified before the next sync. ',
    },
    {
      question: 'What type of alerts do I get ?',
      answer1:
        'We provide users with a mobile application that pushes notifications to your phone as soon as a threshold breach is identified by on of your devices. This can be used by multiple users at the same time and all users will get the push notification with the details of the alert which includes the sensor type, alert values and device details.',
    },
    {
      question: 'Do I get any reports out of the dashboard ?',
      answer1:
        'We provide 3 standard reports, Daily Average, Detailed and a Notifications report.',
      answer2:
        'In case you need a more customized report, we are happy to work with your template and set it up for you.',
    },
  ]

const Faq = () => {
  return (
    <div className="bg-gray-50 py-16">
    <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-16">
      <div className="lg:grid lg:grid-cols-3 lg:gap-8">
        <div>
          <h2 className="text-3xl font-extrabold text-gray-900">Frequently asked questions</h2>
          <p className="mt-4 text-xl text-gray-500">
            Can’t find the answer you’re looking for? Reach out to our{' '}
            <a href="#" className="font-medium text-gradient">
              customer support
            </a>{' '}
            team.
          </p>
        </div>
        <div className="mt-12 lg:mt-0 lg:col-span-2">
          <dl className="space-y-12">
            {faqs.map((faq) => (
              <div key={faq.question}>
                <dt className="text-xl leading-6 font-medium text-gray-900">{faq.question}</dt>
                <dd className="mt-2 text-lg text-gray-500">
                  <p>{faq.answer1}</p>
                  <p>{faq.answer2}</p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  </div>
  );
};

export default Faq;