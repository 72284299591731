import React from 'react';
import Navigation from '../../components/Navigation';
import Footer from '../../components/Footer';
import Faq from '../../components/Faq';
import ContactUs from '../../components/ContactUs';
import Banner from '../../components/Banner';
import KeyFeature from '../../components/KeyFeature';
import RealTimeAccess from '../../components/RealTimeAccess'

const Home = () => {
  return (
    <div className='relative'>
        <Navigation />
        <Banner />
        <KeyFeature />
        <RealTimeAccess />
        <Faq />
        <ContactUs />
        <Footer />
    </div>
  );
};

export default Home;