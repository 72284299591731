import React from 'react';
import Navigation from '../../components/Navigation';
import Footer from '../../components/Footer';
import InnerBanner from '../../components/InnerBanner';
import { AnnotationIcon, MailIcon} from '@heroicons/react/outline'

const communicationFeatures = [
  {
    id: 1,
    name: 'Mobile notifications',
    description:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
    icon: AnnotationIcon,
  },
  {
    id: 2,
    name: 'Reminder emails',
    description:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
    icon: MailIcon,
  },
]


const supportLinks = [
    {
      name: 'Setup of thresholds as per your requirement at a device level.',
      href: '#',
      icon: 'monitor',
    },
    {
      name: 'Provide notification on the device being offline.',
      href: '#',
      icon: 'notification',
    },
    {
      name: 'Interactive Dashboard to monitor all the devices conveniently',
      href: '#',
      icon: 'remote',
    },
    {
        name: 'Supporting features like Mobile App, Email Alerts,SMS , Reports',
        href: '#',
        icon: 'installation',
      },
  ]


const AboutUs = () => {
  return (
    <div>
        <Navigation />
        <InnerBanner  title="About Us" description="An IoT company that works for the betterment of your health, goods and business by keeping them in the pink of health."/>
        <section className={`py-5 relative overflow-hidden bg-white`}>
            <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-16">
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                    <div className="w-full pt-10 pb-20">
                        <h4 className="text-3xl font-extrabold text-gradient mb-5">We Enable</h4>
                        <p className="text-lg text-gray-500 font-normal">Providing ‘plug and play’ solutions for you to keep a track of your environment.We enable you to have a control over some of the most
basic yet crucial elements of your space - air and water.
With an innovative use of technology, our solutions help you monitor, capture and measure the exact conditions of your space at all times. We support with immediate notifications and control measures in case of any catastrophic possibility, making your property secure and your mind, stress-free. </p>
                    </div>
                    <div className="w-full py-8 text-left xs:flex xs:items-center">
                        <img className="w-10/12 relative z-10 float-left sm:float-right"  src={`../../images/we_enable.png`} alt="" />
                    </div>
                </div>
            </div>    
        </section>  
        <section className="py-5 relative overflow-hidden bg-white">
            <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-16">
                <h4 className="text-3xl font-extrabold text-gradient mb-5">We Ensure</h4>
                <div className="w-full flex">
                    <div className="grid grid-cols-1 sm:grid-cols-1 sm:gap-12 lg:grid-cols-2 lg:gap-12">
                        <div className="flex about-ensure">
                            <div className="w-5/12 py-10 about-ensure-left">
                                <h4 className="text-lg font-extrabold text-gray-900 mb-5">QUALITY CONTROL</h4>
                                <p className="text-lg text-gray-500 font-normal">We help in ensuring that your products are stored at just the right temperature and humidity, and from the perspective of human resources, your people are working in a safe and good quality environment.</p>
                            </div>
                            <div className="w-7/12 py-8 text-left xs:flex xs:items-center about-ensure-right">
                                <img className="w-full relative z-10 float-right pl-8"  src={`../../images/quality_control.png`} alt="" />
                            </div>
                        </div>
                        <div className="flex about-ensure">
                            <div className="w-5/12 py-10 about-ensure-left">
                                <h4 className="text-lg font-extrabold text-gray-900 mb-5">HEALTH MEASURES</h4>
                                <p className="text-lg text-gray-500 font-normal">With our super precise and highly efficient sensors, monitoring the indoor air quality of your employees and loved ones is our top most priority. </p>
                            </div>
                            <div className="w-7/12 py-8 text-left xs:flex xs:items-center about-ensure-right">
                                <img className="w-full relative z-10 float-right pl-8"  src={`../../images/health_measures.png`} alt="" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>    
        </section>
        <section className="py-5 relative overflow-hidden bg-white">
            <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-16">
                <h4 className="text-3xl font-extrabold text-gradient mb-5">We Execute</h4>
                <div className="w-full flex">
                    <div className="grid grid-cols-1 sm:grid-cols-1 sm:gap-12 lg:grid-cols-2 lg:gap-12">
                        <div className="flex about-ensure">
                            <div className="w-5/12 py-10 about-ensure-left">
                                <h4 className="text-lg font-extrabold text-gray-900 mb-5">Easy Connectivity</h4>
                                <p className="text-lg text-gray-500 font-normal">Our products are pre-programmed and ready-to-go right out of the box, and all the device needs is an internet (Wi-fi or Sim network) connection and a power point.</p>
                            </div>
                            <div className="w-7/12 py-8 text-left xs:flex xs:items-center about-ensure-right">
                                <img className="w-full relative z-10 float-right pl-8"  src={`../../images/easy_connectivity.png`} alt="" />
                            </div>
                        </div>
                        <div className="flex about-ensure">
                            <div className="w-5/12 py-10 about-ensure-left">
                                <h4 className="text-lg font-extrabold text-gray-900 mb-5">Data Generation</h4>
                                <p className="text-lg text-gray-500 font-normal">After a quick installation, the device immediately starts generating data regarding numerous air variables(temperature, humidity, air quality, etc) and water quality. This data is crucial to maintain a
healthy workspace, home or inventory. </p>
                            </div>
                            <div className="w-7/12 py-8 text-left xs:flex xs:items-center about-ensure-right">
                                <img className="w-full relative z-10 float-right pl-8"  src={`../../images/data_generation.png`} alt="" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>    
        </section>
        <section className="py-10 relative overflow-hidden bg-white">
            <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-16">
                <h4 className="text-3xl font-extrabold text-gray-900 mb-5">We Provide</h4>
                <div className="w-full">
                    <div className="grid xs:grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-16 lg:grid-cols-4 lg:gap-y-0 lg:gap-x-8 mt-20">
                        {supportLinks.map((link) => (
                            <div key={link.name} className="flex flex-col bg-white rounded-2xl shadow-xl">
                            <div className="flex-1 relative pt-16 px-3 pb-8 md:px-5">
                                <div className="absolute top-0 p-5 inline-block background-gradient rounded-xl shadow-lg transform -translate-y-1/2">
                                <img src={`../../images/${link.icon}.png`} className="w-6 text-white" />
                                </div>
                                <p className="text-tiny font-medium text-gray-900">{link.name}</p>
                            </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>    
        </section>
        <section>
        <div className="py-16 bg-white overflow-hidden relative lg:py-24 z-10">
        <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-16 relative z-10">
            <h4 className="text-3xl font-extrabold text-gray-900 mb-5">We Simplfy</h4>
            <div className="w-7/12 float-left simplfy-list">
                <div className="w-3/12 float-left opacity-0 simplfy-list">
                    we simplfy
                </div>
                <div className="w-9/12 float-left md:pt-8 simplfy-list">
                <div className="flex flex-col bg-white rounded-2xl shadow-lg mb-5">
                    <div className="flex-1 relative p-5">
                        <p className="text-tiny font-medium text-gray-900">A centralised and easy to use dashboard to monitor all the devices<br /> at the same time. </p>
                    </div>
                </div>
                <div className="flex flex-col bg-white rounded-2xl shadow-lg mb-5">
                    <div className="flex-1 relative p-5">
                        <p className="text-tiny font-medium text-gray-900">Check the data from any location. </p>
                    </div>
                </div>
                <div className="flex flex-col bg-white rounded-2xl shadow-lg mb-5">
                    <div className="flex-1 relative p-5">
                        <p className="text-tiny font-medium text-gray-900">Receive real-time alerts and notifications.</p>
                    </div>
                </div>
                <div className="flex flex-col bg-white rounded-2xl shadow-lg mb-5">
                    <div className="flex-1 relative p-5">
                        <p className="text-tiny font-medium text-gray-900">See the exact position of all the crucial variables.</p>
                    </div>
                </div>
                <div className="flex flex-col bg-white rounded-2xl shadow-lg mb-5">
                    <div className="flex-1 relative p-5">
                        <p className="text-tiny font-medium text-gray-900">Generate a downloadable report for all the data.</p>
                    </div>
                </div>
                </div>
            </div>
            <div className="w-5/12 float-left simplfy-img">
                <img className="w-10/12 relative z-10 float-right"  src={`../../images/we_simplfy.png`} alt="" />
            </div>
      </div>
      <svg
          className="hidden lg:block absolute z-0 translate-x-48 translate-y-14"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width={404} height={784} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
        </svg>
    </div>
        </section>
        <Footer />
    </div>
  );
};

export default AboutUs;