import React from 'react';
import Navigation from '../../components/Navigation';
import Footer from '../../components/Footer';
import Price from '../../components/Price';

const Pricing = () => {
  return (
    <div>
        <Navigation />
        <Price />
        <Footer />
    </div>
  );
};

export default Pricing;