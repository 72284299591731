import { useState } from 'react'
import ContactModal from './modal/ContactModal';


const Banner = () => {
  const [contactModal, setContactModal] = useState(false);
  return (
    <>{contactModal ? (<ContactModal open={contactModal} onClose={()=> setContactModal(false)} />) : null}
     <div className="relative bg-white overflow-hidden banner-top-margin">
      <div className=" w-full max-w-7xl mx-auto lg:px-16">
        <main className="w-full float-left my-16 mx-auto max-w-7xl sm:my-8 lg:my-12">
          <div className="w-full float-left items-center">
            <div className="mx-auto notfi-banner-left text-left relative z-10">
              <h1>
                <span className="mt-8 sm:mt-16 block text-xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl banner-font-size">
                  <span className="block text-gray-900 banner-text"><span className="text-gradient">M</span>onitor.<span className="text-gradient">C</span>apture.</span>
                  <span className="block text-gray-900 banner-text"><span className="text-gradient">C</span>ontrol.</span>
                 
                </span>
              </h1>
              <button  onClick={() => setContactModal(true)} className="bg-white mt-5 rounded-full flex text-sm focus:outline-none">
                  <a
                  className="inline-flex items-center px-4 py-1 border border-transparent text-lg rounded-md shadow-sm text-white background-gradient"
                >
                  Contact Us
                </a>
                </button>
            
            </div>
            <div className="my-6 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 notfi-banner-right">
              <div className="relative mx-auto w-full">
                <img className="w-full relative z-10"  src="../../images/aura.png" alt="" />
              </div>
            </div>
          </div>
        </main>
      </div>
      <svg
                className="absolute right-0 top-0 z-0"
                width={404}
                height={784}
                fill="none"
                viewBox="0 0 404 784"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="4f4f415c-a0e9-44c2-9601-6ded5a34a13e"
                    x={118}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect x={118} width={404} height={784} fill="url(#4f4f415c-a0e9-44c2-9601-6ded5a34a13e)" />
              </svg>
    </div>
    </>
   
  );
};

export default Banner;