import React,{useState} from 'react';
import { Disclosure, Menu} from '@headlessui/react'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import { NavLink } from 'react-router-dom';
import DownloadApp from './modal/DownloadApp';
import ContactModal from './modal/ContactModal';

const Navigation = () => {
  const [downloadModal, setDownloadModal] = useState(false)
  const [contactModal, setContactModal] = useState(false)
  return (
    <div className='relative'>
      {downloadModal ? (<DownloadApp open={downloadModal} onClose={()=> setDownloadModal(false)} />) : null}
      {contactModal ? (<ContactModal open={contactModal} onClose={()=> setContactModal(false)} />) : null}
      <Disclosure as="nav" className="bg-white shadow mb-2 fixed  top-0 left-0 right-0 z-20">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-16">
              <div className="flex h-16">
                <div className="flex w-full justify-between">
                  <div className="flex-shrink-0 flex items-center">
                  <NavLink
                      to="/"
                      exact 
                    >
                      <img
                      className="block lg:hidden h-8 w-auto"
                      src="../../images/notifi_logo.png"
                      alt="Workflow"
                    />
                    <img
                      className="hidden lg:block h-8 w-auto"
                      src="../../images/notifi_logo.png"
                      alt="Workflow"
                    />
                    </NavLink>
                  </div>
                  <div className="hidden lg:ml-6 lg:flex lg:space-x-8">
                    {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                    <NavLink
                      to="/"
                      exact 
                      activeClassName="border-gradient text-gradient"
                      className="border-transparent text-gray-900 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1  text-lg font-medium"
                    >
                      Home
                    </NavLink>
                    <NavLink
                      to="/product"
                      exact
                      activeClassName="border-gradient text-gradient"
                      className="border-transparent text-gray-900 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-lg font-medium"
                    >
                      Product Features
                    </NavLink>
                    <NavLink
                      to="/price"
                      exact
                      activeClassName="border-gradient text-gradient"
                      className="border-transparent text-gray-900 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-lg font-medium"
                    >
                      Pricing
                    </NavLink>
                    <NavLink
                      exact
                      to="/aboutUs"
                      activeClassName="border-gradient text-gradient"
                      className="border-transparent text-gray-900 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-lg font-medium"
                    >
                      About us
                    </NavLink>
                    <div
                    aria-hidden
                    onClick={() => setContactModal(true)}
                      className="border-transparent cursor-pointer text-gray-900 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-lg font-medium"
                    >
                      Contact us
                    </div>
                    <div
                    aria-hidden
                    onClick={() => setDownloadModal(true)}
                      className="border-transparent cursor-pointer text-gray-900 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-lg font-medium"
                    >
                      Download App
                    </div>
                  </div>
                  <div className="hidden lg:ml-4 lg:flex lg:items-center">
                  <button
                    type="button"
                    className="hidden flex-shrink-0 bg-white p-1 text-lg font-medium text-gray-900 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <span className="sr-only">sign in</span>
                    sign in
                  </button>

                  {/* Profile dropdown */}
                  <Menu as="div" className="ml-4 relative flex-shrink-0 hidden">
                    <div>
                      <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      <a
                      href="#"
                      className="ml-6 inline-flex items-center px-4 py-2 border border-transparent text-lg font-medium rounded-md shadow-sm text-white background-gradient"
                    >
                      Sign Up
                    </a>
                      </Menu.Button>
                    </div>
                  </Menu>
                </div>
                </div>
                
                <div className="flex items-center lg:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>

              </div>
            </div>

            <Disclosure.Panel className="lg:hidden">
              <div className="pt-2 pb-3 space-y-1">
                {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800" */}
                <NavLink
                      to="/"
                      exact 
                      activeClassName="border-gradient-color Nav-active block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                      className="border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                    >
                      Home
                </NavLink>
                <NavLink
                      to="/product"
                      exact 
                      activeClassName="border-gradient-color Nav-active block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                      className="border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                    >
                      Product Features
                </NavLink>
                <NavLink
                      to="/price"
                      exact 
                      activeClassName="border-gradient-color Nav-active block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                      className="border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                    >
                      Pricing
                </NavLink>
                <NavLink
                      to="/aboutUs"
                      exact 
                      activeClassName="border-gradient-color Nav-active block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                      className="border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                    >
                      About Us
                </NavLink>
                <div
                    aria-hidden
                    onClick={() => setContactModal(true)}
                      className="border-transparent cursor-pointer text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                    >
                      Contact us
                    </div>
                    <div
                    aria-hidden
                    onClick={() => setDownloadModal(true)}
                      className="border-transparent cursor-pointer text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                    >
                      Download App
                    </div>
               
              </div>
              <div className="pt-4 pb-3 border-t border-gray-200 hidden">
              <div
                    aria-hidden
                      className="border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                    >
                       sign in
                    </div>
                  {/* Profile dropdown */}
                  <Menu as="div" className="ml-4 relative flex-shrink-0">
                    <div>
                      <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      <a
                      href="#"
                      className="mt-2 inline-flex items-center px-4 py-2 border border-transparent text-lg font-medium rounded-md shadow-sm text-white background-gradient"
                    >
                      Sign Up
                    </a>
                      </Menu.Button>
                    </div>
                  </Menu>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default Navigation;